import { userInitialState } from '../user-initial-state';
import { UserState } from '../user.model';

/**
 * Generate functions are used to keep mocks immutable
 * this prevents mocks from updating in memory and effecting other test
 * aka trying to prevent flaky tests
 */
export const generatedMockUserInitState = (): UserState => {
  return { ...userInitialState };
};

export const generatedMockUserState = (): UserState => {
  return {
    ...{
      userType: 'default',
      entryType: 'new',
      storeStatus: undefined,
      isQuoting: false,
      kinSegmentId: '',
    },
  };
};
