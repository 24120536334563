import { addDays, format } from 'date-fns';
import { QuoteDraftState } from '../../../../global-store/quote-draft';
import { TDateISODate } from '../../../utils/iso-date-type/iso-date.type';

const randomEmail = `test+quoting+${Math.floor(Math.random() * 3000)}@kin.com`;
const validStartDate = format(addDays(new Date(), 10), 'yyyy-MM-dd') as TDateISODate;

export const mockRentalQuoteDraftState: QuoteDraftState = {
  storeStatus: undefined,
  cirId: undefined,
  draftToken: undefined,
  address: {
    state: 'FL',
    street: '2182 153rd Loop',
    city: 'Ocala',
    zipcode: '34473',
  },
  clickLeadsAddress: undefined,
  hasOptedIntoSms: true,
  applicant: {
    dob: '1998-04-02',
    email: randomEmail,
    firstName: 'Debug',
    lastName: 'Console',
    phoneNumber: '(333) 333-3333',
  },
  policyType: 'condominium',
  hasUnitNumber: false,
  unitNumber: '5',
  insuranceStatus: 'currently_insured',
  rentalStatus: true,
  rentalNumber: 9,
  residency: 6,
  policyStartDate: validStartDate,
  hasCoApplicant: true,
  coApplicant: {
    firstName: 'John',
    lastName: 'Smith',
    dob: '1990-01-20',
  },
  propertyType: 'condominium',
  roofShape: 'hip',
  policyGoal: 'both',
  yearBuilt: 2020,
  sqFt: 1540,
  stories: 5,
  pool: true,
  constructionType: 'superior_fire_resistive',
  bathrooms: 2,
  acreage: 0,
  purchaseDate: '2023-03-14',
  roofType: 'composition_3_tab_shingle', // below here was not restored
  selectedPackageInfo: undefined,
  isInsuranceExpiredMoreThan30Days: undefined,
  roofYear: undefined,
  exteriorWall: undefined,
  withinPark: undefined,
  chimney: undefined,
  roofHasSolarPanels: undefined,
  solarPanelStatus: undefined,
  foundationType: undefined,
  selectedPackage: 'Kin Plus',
  submittedFields: {},
};

export const mockCoApplicantQuoteDraftState: QuoteDraftState = {
  storeStatus: 'success',
  cirId: undefined,
  draftToken: undefined,
  address: {
    state: 'FL',
    street: '2182 SW 153rd Loop',
    city: 'Ocala',
    zipcode: '34473',
    unit: '',
  },
  clickLeadsAddress: '2182 SW 153rd Loop, Ocala, FL 34473',
  hasOptedIntoSms: true,
  applicant: {
    dob: '1998-05-20',
    email: randomEmail,
    firstName: 'Debug',
    lastName: 'Console',
    phoneNumber: '(333) 333-3333',
  },
  policyType: 'homeowners',
  insuranceStatus: 'no_insurance',
  rentalStatus: false,
  residency: 12,
  policyStartDate: validStartDate,
  hasCoApplicant: true,
  coApplicant: {
    firstName: 'John',
    lastName: 'Smith',
    dob: '2000-12-05',
  },
  propertyType: 'single_family_detached',
  isInsuranceExpiredMoreThan30Days: false,
  policyGoal: 'both',
  yearBuilt: 2020,
  sqFt: 1540,
  pool: true,
  constructionType: 'frame',
  roofYear: 2020,
  bathrooms: 2,
  hasUnitNumber: undefined,
  rentalNumber: undefined,
  selectedPackageInfo: undefined,
  roofShape: undefined,
  stories: undefined,
  exteriorWall: undefined,
  withinPark: undefined,
  chimney: undefined,
  roofHasSolarPanels: undefined,
  solarPanelStatus: undefined,
  acreage: undefined,
  purchaseDate: undefined,
  roofType: undefined,
  unitNumber: undefined,
  foundationType: undefined,
  selectedPackage: undefined,
  submittedFields: {},
};

export const mockEmptyQuoteDraftState: QuoteDraftState = {
  hasUnitNumber: undefined,
  rentalNumber: undefined,
  selectedPackageInfo: undefined,
  roofShape: undefined,
  stories: undefined,
  exteriorWall: undefined,
  withinPark: undefined,
  chimney: undefined,
  roofHasSolarPanels: undefined,
  solarPanelStatus: undefined,
  acreage: undefined,
  purchaseDate: undefined,
  roofType: undefined,
  storeStatus: undefined,
  draftToken: undefined,
  cirId: undefined,
  address: undefined,
  clickLeadsAddress: undefined,
  applicant: undefined,
  hasOptedIntoSms: false,
  policyType: undefined,
  policyGoal: undefined,
  insuranceStatus: undefined,
  rentalStatus: undefined,
  residency: undefined,
  policyStartDate: undefined,
  hasCoApplicant: undefined,
  propertyType: undefined,
  isInsuranceExpiredMoreThan30Days: undefined,
  coApplicant: undefined,
  yearBuilt: undefined,
  sqFt: undefined,
  pool: undefined,
  constructionType: undefined,
  roofYear: undefined,
  bathrooms: undefined,
  unitNumber: undefined,
  foundationType: undefined,
  selectedPackage: undefined,
  submittedFields: {},
};
